/*
* @Author: Abhi Bhatt
* @Date:   2020-06-24 12:35:52
* @Last Modified by:   abhishek714
* @Last Modified time: 2025-03-28 12:20:55
*/
import React from 'react';
//import {Link} from 'react-router-dom';
import StaticPagesService from './../../services/StaticPagesService/StaticPagesService';
import USMap from '../../components/Map/USMap';


const renderHTML = (rawHTML: string) => 
  React.createElement(
    "div",
    { className: "common-width-div" },  // Add the class here
    React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } })
  );

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contact_data:[]
		}
		this.staticPagesService = new StaticPagesService();
	}

	componentDidMount() {
		const permalink = 'contact';
		if (
      localStorage.getItem('techtop_user_id') != '' &&
      localStorage.getItem('techtop_user_id') != null
    ) {
			this.setState({company: localStorage.getItem('techtop_user_company')});
    }
		this.staticPagesService.getStaticPage(permalink).then(request=>{
			if(request.status===200) {
				this.setState({contact_data:request.data.result.static_page[0].fields})
				//console.log(request.data.result.static_page[0].fields);
			}
		});

	}

	render() {
		let contact_data = this.state.contact_data;
		const { company } = this.state;
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
					<section className="contact-section">		
							<div className="row">
								<div className="col-md-12">
									<div className="default-title">
										<div className="row">
											<div className="col-md-6">	
												<h1 className="m-0 color1">Contact Us</h1>
											</div>
											<div className="col-md-6">	
												<h2 className="m-0 color1 text-end">{company}</h2>
											</div>
										</div>
									</div>
								</div>	
							</div>
							<div className="row">
								<div className="col-md-12">
									{renderHTML(contact_data.content)}
								</div>
							</div>
							<div className="row">	
								<div className="col-md-12">
									<h2 className="color1">Techtop Warehouse Locations</h2>
									<USMap />
								<div> 
							</div>	
							<div className="row">
								<div className="col-md-12">
									<div className="company-address">
										<div className="row">
											<div className="col-md-6 col-sm-6 col-lg-4 col-xl-4 map_location Dallas">
												<div className="company-address-div">
													<h5>Dallas, Tx</h5>
													<p className="m-0">ROBCO</p>
													<p className="m-0">1523 Crescent Drive Carrollton, TX 75006</p>
													<p className="m-0">Phone: 800-444-7609</p>
												</div>
											</div>
											<div className="col-md-6 col-sm-6 col-lg-4 col-xl-4 map_location Phoenix">
												<div className="company-address-div">
													<h5>PHOENIX,AZ</h5>
													<p className="m-0">Phoenix Warehouse Company 4022</p>
													<p className="m-0">West Turney Suite A-1 Phoenix, AZ 85019</p>
													<p className="m-0">Phone: 602-278-2886</p>
												</div>
											</div>
											<div className="col-md-6 col-sm-6 col-lg-4 col-xl-4 map_location Reno">
												<div className="company-address-div">
													<h5>RENO, NV</h5>
													<p className="m-0">Landstar/Advanced Logistics 1285B</p> 
													<p className="m-0">Southern Way Sparks, NV 89431</p>
													<p className="m-0">Phone: 775-432-0336</p>
												</div>
											</div>
											<div className="col-md-6 col-sm-6 col-lg-4 col-xl-4 map_location Houston">
												<div className="company-address-div">
													<h5>HOUSTON, TX</h5>
													<p className="m-0">ROBCO</p>
													<p className="m-0">5630 Guhn Road # 100 Houston, TX 77040</p>
													<p className="m-0">Phone: 800-444-7609</p>
												</div>
											</div>
											<div className="col-md-6 col-sm-6 col-lg-4 col-xl-4 map_location Fresno">
												<div className="company-address-div">
													<h5>FRESNO, CA</h5>
													<p className="m-0">Engineered Industrial Products, Inc</p> 
													<p className="m-0">1816 S Van Ness Ave. Fresno, CA 93721</p>
													<p className="m-0">Phone: 559-365-7949</p>
												</div>
											</div>
											<div className="col-md-6 col-sm-6 col-lg-4 col-xl-4">
												<div className="company-address-div">
													<h5>CLEVELAND, OH</h5>
													<p className="m-0">IMC</p>
													<p className="m-0">4540 Glennbrook Dr. Willoughby, OH 44094</p>
													<p className="m-0">Phone: 440-951-5703 x8</p>
												</div>
											</div>
											<div className="col-md-6 col-sm-6 col-lg-4 col-xl-4 map_location Odessa">
												<div className="company-address-div">
													<h5>ODESSA, TX</h5>
													<p className="m-0">Diversified Warehouse, Inc</p> 
													<p className="m-0">635 Industrial Ave Odessa, TX 79761</p>
													<p className="m-0">Phone: 432-332-0416</p>
												</div>
											</div>
											<div className="col-md-6 col-sm-6 col-lg-4 col-xl-4 map_location Portland">
												<div className="company-address-div">
													<h5>PORTLAND, OR</h5>
													<p className="m-0">Northwest Warehouse</p> 
													<p className="m-0">7615 N. Upland Dr. Portland, OR 97203</p>
													<p className="m-0">Phone: 503-735-4082</p>
												</div>
											</div>
											<div className="col-md-6 col-sm-6 col-lg-4 col-xl-4 map_location Itasca">
												<div className="company-address-div">
													<h5>ITASCA, IL</h5>
													<p className="m-0">4C Logistics</p> 
													<p className="m-0">921 Ardmore Ave Itasca, IL 60143</p>
													<p className="m-0">Phone: 630-775-9103</p>
												</div>
											</div>
										</div>
									</div>	
								</div>
							</div>		
						</div>
					</div>
					</section>	
				</main>
			</React.Fragment>
		)
	}
}

export default Contact;